@import "../../styles//variables.scss";

.login-box {
    justify-self: center;
    align-self: center;
    margin-top: 10vh;
    margin-bottom: 10vh;
    background-color: white;
    border-radius: 10px;
    padding: 4vh 8vw;
    box-shadow: 10px 10px 20px lightgrey;
    display: flex;
    flex-direction: column;
    min-height: 50vh;
    justify-content: space-evenly;
    position: relative;

    .login-mask {
        background-color: white;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        opacity: 0.5;
        display: flex;
        flex-direction: column;
    }
    
    .login-logo {
        width: 100%;
        max-width: 300px;
        height: auto;
        align-self: center;
    }

    .login-input {
        padding: 10px;
        border: 2px solid $primary;
        border-radius: 20px;
        margin-top: 10px;
        min-width: 300px;
        max-width: 80%;
        align-self: center;
    }

    .login-input.error {
        border: 1px solid $error;
    }

    .login-options-container {
        display: flex;

        .login-forgot-text {
            color: $primary;
            margin-left: auto;
            cursor: pointer;
        }
    }

    .forgot-header-text {
        text-align: center;
        color: gray;
        font-weight: bold;
        font-size: 1.2em;
    }

    .forgot-desc-text {
        color: black;
        text-align: center;
        font-weight: bold;
        max-width: 60%;
        align-self: center;
    }

    .login-button {
        width: 70%;
        margin: 0 auto;
        background-color: $primary;
        color: white;
        cursor: pointer;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 5px 0;
        text-transform: uppercase;
        font-size: 1.2em;
        border-radius: 5px;
    }
}