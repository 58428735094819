@import "../styles/variables.scss";

body > .print-order {
    display: flex;
}

@media print {
    .print-order {
        margin: 5vh 5vw;
        display: flex;
        flex-direction: column;
    }
}

body {
    margin: 0 !important;
    font-size: 12px;
    font-family: 'Lato', sans-serif;
}

.root {
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
}

.App-Container {
    left: 0;
    position: relative;
    transition: 1s;
}

.mobile-menu {
    position: absolute;
    display: flex;
    flex-direction: column;
    height: 100%;
    left: -40vw;
    width: 40vw;
    background-color: $primary;
    transition: 1s;
}

.App {
    margin-top: 14vh;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    padding: 2vh 5vw;
}

.ReactModal__Overlay {
    z-index: 1000;
}

.page-header-container {
    display: flex;
    width: 100%;
    border-bottom: 3px solid #020E5A;

    .page-header-close {
        margin-left: auto;
        cursor: pointer;
    }
}

.form-row {
    display: flex;
    justify-content: space-around;
    margin: 10px;

    .input-container {
        width: 40%;
    }

    .form-50 {
        width: 40%;
        display: flex;
        flex-direction: column;

        .input-container {
            width: 48.5%;
            margin: 10px 0;
        }
    }

    .form-50.full-size {
        .input-container {
            width: 100% !important;
        }
    }

    .form-50.full {
        .input-container {
            width: 100%;
        }
    }

    .form-title {
        font-weight: bold;
        width: 25%;
        text-align: left;
    }

    .form-desc {
        text-decoration: underline;
        display: flex;
        text-align: left;
    }

    .input-box {
        width: 96%;
        padding: 9px 5px;
    }
    
    .input-dropdown {
        width: 97%;
    }
}

.view-box {
    margin: 10px 12.5vw;
    border: 1px solid gray;
    padding: 10px;
    display: flex;
    flex-direction: column;

    border-radius: 10px;
    box-shadow: 5px 10px 10px lightgray;
    font-size: 1.3em;

    .box-header {
        border-bottom: 1px solid black;
        margin: 5px 10px;
        padding: 10px;
        font-weight: 600;
        color: #646464;
    }

    .box-contents {
        display: flex;
        flex-direction: column;
        overflow-x: none;
        padding: 5px 2.5%;
        overflow-y: auto;
        max-height: 300px;

        .box-details {
            display: flex;
            margin: 7px 10px;

            .details-header {
                font-weight: bold;
                width: 40%;
            }

            .details-info {
                width: 60%;
                text-decoration: underline;
            }
        }
    }
}

.inventory-container {
    max-height: 300px;
    overflow-y: auto;
    flex-direction: column;
    margin: 10px;
    border: 1px solid grey;
    border-radius: 5px;
    box-shadow: 2px 4px 5px lightgray;

    .order-inventory-headers {
        border-bottom: 1px solid lightgray;
        padding: 5px;
        display: flex;

        div {
          font-weight: bold;
          font-size: 0.7em;
          text-transform: uppercase;  
          text-align: center;
        }
    }

    .order-inventory-row {
        border-bottom: 1px solid lightgray;
        padding: 3px;
        font-weight: bold;
        display: flex;
        
        div {
            font-weight: bold;
            font-size: 0.7em;
            text-align: center;
        }
    }
}

.flex {
    display: flex;
    justify-content: space-around;

    .order-log {
        width: 40%;
        margin: 10px 10px 10px 12.5vw;
        position: relative;
    }

    .comments-log {
        width: 63%;
        margin: 10px 12.5vw 10px 10px;
        max-height: 420px;
        overflow-y: auto;
    }
}

.justify {
    justify-content: space-around;
}

.input-box.error {
    border: 1px solid red !important;
}


.input-dropdown.error {
    border: 1px solid red;
}

.images-container {
    max-width: 95%;
    height: 120px;
    display: flex;
    overflow-x: auto;
    padding: 0px 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.box-images {
    display: flex;
    border: 1px dashed;
    margin: 10px 20px;
    justify-content: center;
    overflow-x: auto;
    padding: 0 5px;

    .image-container {
        min-height: 140px;
        max-height: 140px;
        min-width: 140px;
        max-width: 140px;
        margin: 10px 20px 10px 0;
        position: relative;
    }
}

.image-container {
    min-height: 60px;
    max-height: 60px;
    min-width: 60px;
    max-width: 60px;
    margin: 10px 20px 10px 0;
    position: relative;


    .image-delete {
        position: absolute;
        cursor: pointer;
        right: -5px;
        top: -7px;
        border-radius: 50%;
        background-color: white;
    }

    .image-square {
        height: 100%;
        width: 100%;
    }
}

.inline {
    display: flex;
}

.button {
    padding: 7px 10px;
    border-radius: 15px;
    cursor: pointer;
    box-shadow: 2px 2px 5px lightgray;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;

    .button-symbol {
        font-weight: bold;
        font-size: 2em;
        margin-right: 5px;
        color: white;
    }
}

.button.primary {
    background-color: $primary;
    color: #CED1DF;
}

.button.danger {
    color: #CED1DF;
    background-color: $error;
}

.button.large {
    font-size: 1.4em;
}

.error-message {
    color: $error;
    font-size: 1em;
    text-align: center;
}

.page-header {
    padding: 2vh 0;
    border-bottom: 5px solid $primary;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3vh;

    .header-text {
        font-size: 1.6em;
        text-transform: uppercase;
        color: #646464;
        font-weight: bold;
    }

    .page-header-close {
        color: $primary;
        font-size: 3em;
        cursor: pointer;
        transition: 0.5s;
    }

    .header-desc {
        display: flex;
        width: 50%;
        justify-content: flex-end;

        .input-container {
            justify-content: center;
            align-items: center;

            div {
                margin-right: 10px;
            }

            input {
                border-radius: 0;
            }
        }
    }

    .page-header-desc {
        display: flex;
        justify-content: space-between;
        width: 70%;

        .days-left {
            color: white;
            font-size: 1.2em;
            border-radius: 50%;
            padding: 10px;
            background-color: #AE0403;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .cancelled {
            color: white;
            font-size: 1.2em;
            padding: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-right: 5px;
            background-color: #AE0403;
            text-align: center;
        }

        .order-days-breakdown {
            display: flex;
            flex-direction: column;
            width: 80%;
            justify-content: space-around;

            .order-days-highlighted {
                background-color: yellow;
            }
            
            div {
                width: 100%;
                display: flex;
                justify-content: space-between;

                .order-days-text {
                    font-weight: bold;
                    font-size: 1.3em;
                }

                .order-days-date {
                    font-size: 1.3em;
                    text-decoration: underline;
                }

            }
        }
    }
    
}

.table-page {
    .page-options {
        display: flex;
        margin-bottom: 20px;;

        .order-types {
            margin-left: auto;
            border: 1px solid #B5B5B5;
            border-radius: 5px;
            padding: 5px;
            display: flex;

            .order-type {
                border: 1px solid #08CE14;
                font-size: 1em;
                font-weight: bold;
                color: #08CE14;
                padding: 5px;
                margin: 0 2.5px;
                cursor: pointer;
                transition: 0.5s;
                border-radius: 5px;
            }

            .order-type:hover {
                background-color: #08CE14;
                color: white;
            }

            .order-type.active {
                background-color: #08CE14;
                color: white;
            }

           
        }

        .table-pages {
            margin-left: auto;
            padding: 5px;
            display: flex;

            span {
                margin: auto 5px;
            }

            .arrow {
                margin: auto 5px;
                color: lightgray;
            }

            .arrow.active {
                margin: auto 5px;
                cursor: pointer;
                color: $secondary;
                transition: 0.5s;
            }

            .arrow.active:hover {
                color: $primary;
            }
        }
    }

    .table {
        position: relative;
        
        .loading-mask {
            position: relative;
            width: 100%;
            height: 100%;
            background: white;
            opacity: 0.5;
            top: 0;
            left: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    table {
        border: 1px solid gray;
        width: 100%;
        border-collapse: collapse;
        border-radius: 50%;
        margin: 10px 0;
        color: gray;
        font-weight: 600;
        font-size: 1.2em;

        th {
            background-color: $light-blue;
            border: 1px solid gray;
            padding: 5px 0;

            div {
                justify-content: center;
                align-items: center;
            }

            .table-sort-arrow {
                margin-left: 5px;
                color: $secondary;
                font-size: 1.2em;
            }

            .table-sort-arrow.up {
                margin-top: 3px;
            }

            .table-sort-arrow.down {
                font-size: 1.6em;
                margin-top: -10px;
            }
        }

        th.sortable {
            cursor: pointer;
        }

        tr {
            transition: 0.3s;
        }

        tr:hover {
            background-color: lightgray;
            cursor: pointer;
        }

        td {
            border: 1px solid gray;
            text-align: center;
            padding: 5px 0;
        }
    }
}

.searchbar-container {
    display: flex;
    width: 35%;

    .searchbar {
        display: flex;
        background-color: #D0DDF0;
        border-radius: 4px;
        padding: 2.5px;
        width: 100%;

        .searchbar-icon {
            margin: auto 5px auto 0 ;

        }

        input {
            border: none !important;
            background-color: #D0DDF0;
            color: gray;
            width: 100%;
            padding: 0;
            margin: 0;
        }

        input:focus {
            border: none !important;
            outline: none;
        }

        input:placeholder {
            font-weight: bold;
        }
    }

    .searchbar-cancel {
        margin: auto 5px;
        cursor: pointer;
        font-size: 1.3em;
    }
}

.width-90 {
    width: 90%;
}

.phase-box {
    margin: 0 10px;
    color: white;
    border-radius: 10px;
    padding: 5px 0;
    font-weight: 400;
}

.input-container {
    .input-title {
        font-weight: 600;
        font-size: 1.2em;
        margin-bottom: 5px;
        color: #141414;
    }
}

.input-dropdown:focus {
    border: $primary !important;
}

.input-box {
    align-items: center;
    background-color: hsl(0,0%,100%);
    border-color: hsl(0,0%,80%);
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    font-family: 'Lato', sans-serif;
}

.input-box:disabled {
    background-color: lightgray;
}

.input-box:focus {
    border: $primary !important;
}

.upload-images-container {
    display: flex;
    overflow-x: auto;
    width: 70%;
    min-height: 75px;
    border: 1px dashed;
    margin: 10px auto !important;
    border-radius: 5px;
    justify-content: center;
    align-items: center;
}

.form-desc {
    .upload-images-container {
        margin: 10px !important;
        width: 100% !important;
        overflow-x: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;

        .image-container {
            margin: 10px 10px !important;
            padding: 5px;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.inventory-page {
    .form-row {
        font-size: 1.2em;
        justify-content: unset;
    }
}

.quantityInput {
    width: 40px;
}

.locationInput {
    width: 70px;
}

.blue-border {
    border-right: 10px solid lightblue;
    border-left: 10px solid lightblue;
    border-top: 3px solid lightblue;
    border-bottom: 3px solid lightblue;
    border-radius: 3px;
}

.barcode-row {
    .form-desc {
        display: flex;

        .print-button {
            background-color: white;
            border: 1px solid gray;
            padding: 5px;
            font-size: 0.7em;
            font-weight: bold;
            border-radius: 5px;
            height: 25px;
            justify-content: center;
            align-items: center;
            display: flex;
            cursor: pointer;
            margin: auto auto auto 0px;
        }
        
        .print-button:hover {
            background-color: lightgray;
        }
        
        svg {
            height: 100px;
            margin-left: -50px;
        }
    }
}

.delete-modal-overlay {
    width: 40% !important;
    height: 50% !important;
    top: 20% !important;
    left: 30% !important;
    right: 40px;
    bottom: 40px;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    position: absolute;
}

.delete-modal-overlay.upload {
    right: 40px;
    bottom: 40px;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
}

.delete-modal {
    display: flex;
    flex-direction: column;
    padding: 20px;

    .confirm-delete {
        text-transform: uppercase;
        color: $primary;
        font-size: 1.4em;
        text-align: center;
        width: 60%;
        margin: 0 auto;
    }

    .desc-delete {
        color: $primary;
        margin: 20px 0;
        text-align: center;
        font-size: 1.2em;
    }

    .button {
        width: 40%;
        margin: 15% auto 0;
        font-size: 1.1em;
        background-color: darkred;
    }

    .button.primary {
        margin: 20px auto 0;
        background-color: $primary;
    }

    .button.primary.disabled {
        opacity: 0.5;
    }

    .cancel-delete {
        color: $primary;
        text-transform: uppercase;
        margin: 20px 0;
        cursor: pointer;
        text-align: center;
        font-size: 1.2em;
    }
}

@media (max-width: 768px){
    body {
        margin: 0 !important;
        font-size: 10px;
        font-family: 'Lato', sans-serif;
    }

    .App {
        overflow-x: hidden;
        padding: 0 2vw;
    }

    .mobile-menu.mobile-active {
        left: 0vw;
        transition: 1s;
    }

    .App-Container.mobile-active { 
        position: absolute;
        left: 40vw;
    }

    .mobile-hidden {
        display: none;
    }

    .mobile-header {
        flex-wrap: wrap;
        
    }

    .page-options > .searchbar-container {
        width: 100%;
    }

    .mobile-header > div {
        width: 100%;
        margin: 10px 0;
    }

    .page-options {
        flex-wrap: wrap;
    }
    
    .order-types {
        div {
            width: 50%;
        }
    }

    .table-pages {
        width: 100%;
        padding: 5px;
        justify-content: space-between;
        padding: 10px 0;
        margin-top: 10px;
        font-size: 14px;
    }

    .table {
        overflow-x: auto;
    }

    th {
        padding: 5px 8px !important;
    }

    .ReactModal__Content {
        width: calc(100% - 40px);
        height: 100%;
        top: 0 !important;
        left: 0 !important;
    }

    .delete-modal-overlay {
        width: 60% !important;
        height: 50% !important;
        top: 20% !important;
        left: 15% !important;
        right: 40px;
        bottom: 40px;
        border: 1px solid rgb(204, 204, 204);
        background: rgb(255, 255, 255);
        overflow: auto;
        border-radius: 4px;
        outline: none;
        padding: 20px;
        position: absolute;

        .button {
            text-align: center;
        }
    }

    .form-row {
        flex-wrap: wrap;
        
        .button {
            margin: 10px 0 30px 0;
        }
    }

    .form-row > div {
        width: 100% !important;
        margin: 10px 0;
    }

    .inventory-page {
        .form-row {
            flex-wrap: nowrap;
        }
    }

    .order-dates {
        flex-direction: column;
    }
    
    .form-50  {
        flex-wrap: wrap;

        .input-container {
            width: 100% !important;
        }
    }

    .order-dates > div {
        margin: 5px 0;
    }

    .react-datepicker-wrapper {
        width: 100%;
    }

    .modal {
        .page-header, {
            flex-wrap: wrap;
            
            .header-text, .header-desc {
                width: 100%;

                .page-header-desc {
                    width: 100%;

                    .input-container {
                        width: 100%;
                    }
                }


            }

            .header-text {
                text-align: center;
                margin: 0 0 10px;
            }
        }

        .page {
            padding-bottom: 40px;
            .view-box {
                margin: 10px !important;
            }

            .flex {
                flex-wrap: nowrap;
                .order-log {
                    width: 45%;
                }
    
                .comments-log {
                    width: 45%;
                }
            }
        }
    }
}


