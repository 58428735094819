@import "../../styles//variables.scss";

.header {
    width: 100%;
    height: 8vh; 
    position: absolute;
    background-color: #738085;
    display: flex;
    align-items: center;
    padding: 2vh;

    .mobile-hamburger, .mobile-action, .mobile-header {
        display: none;
    }

    .desktop-logo {
        img {
            width: 40%;
            height: auto;
        }
    }

    .header-options {
        margin-left: auto;
        display: flex;
        justify-content: space-around;
        min-width: 55%;
        margin-right: 20px;

        .header-opt {
            color: white;
            padding: 5px 0;
            border-top: 3px solid white;
            font-size: 1.2vw;
            text-decoration: none;
            transition: 0.5s;
            cursor: pointer;
        }

        .header-opt:hover {
            border-top: 3px solid red;
        }

        .header-opt.selected {
            border-top: 3px solid red;
        }

        a {
            text-decoration: none;
        }
    }
}

@media (max-width: 750px){
    .header {
        .desktop-logo {
            display: none;
        }

        .mobile-hamburger {
            display: flex;
            flex-direction: column;
            margin: auto 0;
            cursor: pointer;
        
            .bar1, .bar2, .bar3 {
                height: 3px; 
                width: 30px;
                margin: 4px 0;
                transition: 0.4s;
                background-color: white;
            }
        
            .change.bar1 {
                -webkit-transform: rotate(-45deg) translate(-9px, 6px);
                transform: rotate(-45deg) translate(-9px, 6px);
              }
              
            .change.bar2 {opacity: 0;}
            
            .change.bar3 {
            -webkit-transform: rotate(45deg) translate(-8px, -8px);
            transform: rotate(45deg) translate(-8px, -8px);
            width: 30px;
            }
        
            .bar3 {
                width: 15px;
                margin: 4px auto 2px 0;
            }
        }

        .mobile-action {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: absolute;
            right: 50px;
            top: 20px;

            .action-circle {
                margin-bottom: 5px;
                background-color: white;
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 4em;
                color: $primary;
                border-radius: 50%;
                font-weight: bold;
                color: $primary;
                width: 40px;
                height: 40px;
            }

            .action-text {
                color: white;
                font-size: 1em;
                text-transform: uppercase;
            }
        }

        .mobile-header {
            display: flex;
            left: 25%;
            color: white;
            font-size: 2em;
            text-transform: uppercase;
            position: absolute;
        }

        .header-options {
            display: none;
        }
    }
}