.mobile-menu {
    display: flex;
    flex-direction: column;
    padding: 15% 0;

    .mobile-opt {
        color: white;
        font-size: 0.8em;
        text-decoration: none;
        margin: 20px 20px;
        text-transform: uppercase;
    }

    .mobile-opt.selected {
        color: lightblue;
    }
}